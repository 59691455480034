body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #f9f9f9;
}

.app {
  margin: 70px 20px;
}
