/* Overriding existing properties requires use of !important */

.ril__outer {
  background: rgba(0, 0, 0, 0.75) !important;
}

.ril__caption, .ril__toolbar {
  background: rgba(0, 0, 0, 1) !important;
}

.ril__captionContent {
  display: flex;
  flex: 1;
  padding: 10px 20px 20px !important;
}

.ril__navButtons {
  outline: none;
}
